import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "/src/components/Layout";
import * as styles from "./discipline.module.css";
import BookNow from "/src/components/BookNow";
import { SEO } from "/src/components/SEO";
import userIcon from "/src/images/user.svg";

const TreatmentPage = (props) => {
  const {
    data: { discipline },
  } = props;

  return (
    <Layout>
      <div className={styles.disciplineCtr}>
        <h1>{discipline.name}</h1>
        <p>
          <div
            dangerouslySetInnerHTML={{ __html: discipline.description?.html }}
          />
        </p>
        <div className={styles.practitionerCtr}>
          <h2>Practitioners</h2>
          <div className={styles.practitionerGrid}>
            {discipline.practitioners
              .filter((p) => p.active === true)
              .sort((a, b) => a.orderPosition - b.orderPosition)
              .map((item, index) => (
                <div className={styles.practitioner} key={index}>
                  <Link to={`/practitioners/${item.urlSlug}`}>
                    {item.photo?.url ? (
                      <img
                        className={styles.practitionerLogo}
                        src={item.photo.url}
                        alt=""
                      />
                    ) : (
                      <div className={styles.noPhoto}>
                        {item.firstName ? item.firstName?.[0].toUpperCase() : null}
                        {item.lastName ? item.lastName?.[0].toUpperCase() : null}
                      </div>
                    )}
                  </Link>
                  <Link to={`/practitioners/${item.urlSlug}`}>
                    <span>
                      {item.prefix ? item.prefix + " " : null}
                      {item.firstName ? item.firstName + " " : null}
                      {item.lastName ? item.lastName : null}
                      {item.suffix ? ", " + item.suffix : null}
                    </span>
                  </Link>
                </div>
              ))}
          </div>
        </div>
        <div style={{ width: "fit-content" }}>
          <BookNow label={`Book ${discipline.name} Appointment`} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    discipline: graphCmsDiscipline(urlSlug: { eq: $slug }) {
      name
      description {
        html
      }
      practitioners {
        prefix
        firstName
        lastName
        suffix
        urlSlug
        orderPosition
        active
        photo {
          url
        }
      }
    }
  }
`;

export default TreatmentPage;

export const Head = () => <SEO />;
